












import { computed, defineComponent, reactive } from '@vue/composition-api'
import { ChartData, ChartOptions } from 'chart.js'
import interpolate from 'color-interpolate'

const colormap = interpolate(['#03389f', '#4CAF50'])

export default defineComponent({
  components: {
    AChartDoughnut: () => import('@/components/atoms/a-chart-doughnut.vue')
  },

  setup() {
    const state = reactive({
      datacollection: computed(() => {
        const data: ChartData = {
          datasets: [
            {
              label: 'Najlepsi kierowcy',
              data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => Math.round(500 + v * 50 + Math.random() * 10)),
              backgroundColor: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => colormap(v / 10))
            }
          ],
          // labels: ['ZS 595LM', 'WL 8414N', 'NO 9439P', 'SR 3958L', 'KR 4SG29', 'DW 4W156', 'DKL 39028', 'HPZ C028', 'KLI 69YR', 'SRB 7KC9'],
          labels: ['ZS [DEMO]', 'WL [DEMO]', 'NO [DEMO]', 'SR [DEMO]', 'KR [DEMO]', 'DW [DEMO]', 'DKL [DEMO]', 'HPZ [DEMO]', 'KLI [DEMO]', 'SRB [DEMO]'],
        }

        return data
      }),
      options: computed(() => {
        const data: ChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            callbacks: {
              label: (item, data) => ` Średnio ${data.datasets && data.datasets[0] && data.datasets[0].data ? data.datasets[0].data[item.index ? item.index : 0] : ''} zł oszczędności miesięcznie`,
              title: (item, data) => `${data.labels ? data.labels[item[0].index ? item[0].index : 0] : ''}`
            }
          },
          legend: {
            position: 'left',
            reverse: true,
            labels: {
              usePointStyle: true
            }
          }
        }

        return data
      })
    })

    return { state }
  }
})
